<template>
    
    <DataTable ref="dt" 
        :value="items.data" dataKey="id" 
        :totalRecords="items.total"
        :sortField="sort" :sortOrder="sortOrder"
        :paginator="true" :rows="20" :alwaysShowPaginator="false" :filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
        currentPageReportTemplate="{first}-{last} of {totalRecords}" responsiveLayout="scroll"
        :lazy="true" :loading="loading" @page="onPage($event)" @sort="onSort($event)" @filter="onFilter($event)" @rowClick="onrowclick"
    >
        
        <template #header v-if="showHeader">
            <div class='row justify-content-between' id="overview-header">
                <div :class="('w-' + (pushLeft ? '25' : '50'))">
                    <h2 class="pt-1 pb-3">{{moduleNamePlural}}</h2>
                </div>
                <div :class="('d-flex w-' + (pushLeft ? '75 gap-2' : '50'))">
                    <div class="form-group search" :style="{width: (pushLeft ? '50%' : '100%')}">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        <input type="search" class="form-control" id="search" :placeholder="$t('overview.search')" @change="onChange()" @input="onInput()" @keypress.enter.prevent="onEnter()" v-model="filters['global'].value" autocomplete="off">
                    </div>
                    <div v-if="pushLeft" class="d-flex gap-2" :style="{marginRight: '170px', width: '50%'}">
                        <input class="form-control header-field" v-model="dateFrom" @change="onDateChange()" placeholder="Vanaf" @focus="setDatePickerActive" @blur="setDatePickerInctive">
                        <input class="form-control header-field" v-model="dateTo" @change="onDateChange()" placeholder="t.m." @focus="setDatePickerActive" @blur="setDatePickerInctive">
                    </div>
                    <button class="btn w-auto h-auto collapsed ms-4" style="margin-right: 19px" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" v-if="filterGroups">
                        <i class="fa fa-bars-filter"></i>
                    </button>
                </div>
            </div>

            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#overview-header" v-if="filterGroups">
                <div class="accordion-body">
                    <template v-for="filtergroup in filterGroups" :key="filtergroup.name">
                        <div class="d-flex fw-bold mb-2">{{name}}</div>
                        <div class="d-flex gap-2 mb-3">
                            <label :for="'filter-'+filtergroup.field+'-all'" class="inputgroup col-auto" style="width: 20%">
                                <input type="checkbox" :id="'filter-'+filtergroup.field+'-all'" checked="" @click="setExtrafilters(filtergroup.field, 'all')" />
                                <span>Alle {{filtergroup.name}}</span>
                            </label>
                            <template v-for="filter, index in filtergroup.values" :key="filter.id">
                                <label :for="'filter-'+filter.id" class="inputgroup col-auto" :style="{width: (index == 0 || index == 1 ? '12%' : '26%')}">
                                    <input type="checkbox" :id="'filter-'+filter.id" @click="setExtrafilters(filtergroup.field, filter.id)" :data-field="filtergroup.field" />
                                    <span>{{filter.name}}</span>
                                </label>
                            </template>
                        </div>
                    </template>
                </div>
            </div>
            
        </template>

        <slot name="columns"></slot>
        <slot name="actionscolumn">
            <Column header="" :exportable="false" style="width:90px">
                <template #body="slotProps">
                    <div :class="showDropdown ? 'dropdown d-inline' : 'd-none'">
                        <button type="button" id="dropdownMenuButton1" class="btn dropdownmenubtn" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa-light fa-ellipsis-vertical px-2"></i></button>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                            <template v-if="(slotProps.data.contract || slotProps.data.employee_id) && slotProps.data.employee_status">
                                <template v-if="slotProps.data.contract && slotProps.data.employee_status.name == 'in afwachting van gegevens'"> <!-- In afwachting gegevens -->
                                    <li>
                                        <a class="dropdown-item text-danger" data-bs-toggle="modal" data-bs-target="#deleteModal" @click="delItem(slotProps.data, true)">
                                            <i class="fal fa-trash-alt"></i> {{ $t('overview.delete') }}
                                        </a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" @click="setHref(0, slotProps.data)">
                                            <i class="fal fa-pen"></i> {{ $t('overview.change') }}
                                        </a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#formModal" @click="editItem(slotProps.data, true, 'reminder')">
                                            <i class="fal fa-envelope"></i> {{ $t('overview.rem_mail') }}
                                        </a>
                                    </li>
                                </template>
                                <li v-if="slotProps.data.employee_status && (slotProps.data.employee_status.name == 'in dienst' || slotProps.data.employee_status.name == 'in afwachting van gegevens')"> <!-- NAW-gegevens -->
                                    <a class="dropdown-item" @click="setHref(1, slotProps.data)">
                                        <i class="fal fa-pen"></i> {{ $t('overview.employee_action_1' + (slotProps.data.employee_status_id == 1 ? 'b' : 'a')) }}
                                    </a>
                                </li>
                                <template v-if="slotProps.data.employee_status && slotProps.data.employee_status.name.includes('ondertekenen contract')"> <!-- In afwachting ondertekening -->
                                    <li>
                                        <a class="dropdown-item" @click="setHref(!slotProps.data.contract ? 2 : 0, slotProps.data)">
                                            <i class="fal fa-pen"></i> {{ $t('overview.employee_action_2a') }}
                                        </a>
                                    </li>
                                    <li v-if="isHR">
                                        <a class="dropdown-item" @click="sync(slotProps.data)">
                                            <i class="fal fa-sync"></i> Synchroniseer met NMBRS
                                        </a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" @click="sendSignRequest(slotProps.data)">
                                            <i class="fal fa-send"></i> {{ $t('overview.employee_action_5') }}
                                        </a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#formModal" @click="editItem(slotProps.data, true, 'wontbeemployed')">
                                            <i class="fal fa-envelope"></i> {{ $t('overview.employee_action_6a') }}
                                        </a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" @click="sendFinalizationRequest(slotProps.data)">
                                            <i class="fal fa-clipboard-check"></i> {{ $t('overview.employee_action_7') }}
                                        </a>
                                    </li>
                                </template>
                                <template v-if="slotProps.data.employee_status.name == 'contract afgekeurd'"> <!-- Contract afgekeurd -->
                                    <li>
                                        <a class="dropdown-item" @click="setHref(!slotProps.data.contract ? 2 : 0, slotProps.data)">
                                            <i class="fal fa-pen"></i> {{ $t('overview.employee_action_2a') }}
                                        </a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#formModal" @click="editItem(slotProps.data, true, 'wontbeemployed')">
                                            <i class="fal fa-envelope"></i> {{ $t('overview.employee_action_6a') }}
                                        </a>
                                    </li>
                                </template>
                                <template v-if="slotProps.data.employee_status.name == 'in dienst'"> <!-- In dienst -->
                                    <li>
                                        <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#contractChangeOptionsModal" @click="savedData = slotProps.data">
                                            <i class="fal fa-pen"></i> {{ $t('overview.employee_action_2b') }}
                                        </a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#formModal" @click="editItem(slotProps.data, true, 'endemployment')">
                                            <i class="fal fa-envelope"></i> {{ $t('overview.employee_action_6b') }}
                                        </a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#formModal" @click="editItem(slotProps.data, true)">
                                            <i class="fal fa-envelope"></i> {{ $t('overview.employee_action_6') }}
                                        </a>
                                    </li>
                                </template>
                                <li v-if="slotProps.data.contract">
                                    <a class="dropdown-item" @click="$router.push({ name: 'show_employee_main', params: { id: slotProps.data.contract.id } })">
                                        <i class="fal fa-memo"></i> {{ $t('overview.employee_details') }}
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item" @click="setAuditTrail(slotProps.data)">
                                        <i class="fal fa-user-magnifying-glass"></i> {{ $t('overview.employee_revise') }}
                                    </a>
                                </li>
                            </template>
                            <template v-else>
                                <li>
                                    <a class="dropdown-item text-warning" data-bs-toggle="modal" data-bs-target="#formModal" @click="editItem(slotProps.data)">
                                        <i class="fal fa-pen"></i> {{ $t('overview.edit') }}
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item text-success" v-if="showDownload" @click="downloadPDF(slotProps.data)">
                                        <i class="fal fa-download"></i> Download {{ $t('overview.download_pdf') }}
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item text-info" v-if="showCopy" data-bs-toggle="modal" data-bs-target="#formModal" @click="copyItem(slotProps.data)">
                                        <i class="fal fa-copy"></i> {{ $t('overview.copy') }}
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item text-danger" data-bs-toggle="modal" data-bs-target="#deleteModal" @click="delItem(slotProps.data)">
                                        <i class="fal fa-trash-alt"></i> {{ $t('overview.delete') }}
                                    </a>
                                </li>
                            </template>
                        </ul>
                        
                    </div>
                    
                </template>
            </Column>
        </slot>
    </DataTable>

    <FormPopup v-on:save="save" v-on:del="del" :itemId="item ? item.id : null" :itemName="item ? (item.employee_name ? item.employee_name : item[sort]) : null" :moduleName="moduleName" :loading="itemLoading" 
              :modalSize="modalSize" :modalHeight="modalHeight" :modalWidth="modalWidth" :isCopy="isCopying" :isMail="isMail">
        <slot name='form'></slot>
        <template #modal-footer><slot name="modal-footer"></slot></template>
    </FormPopup>

    <div class="modal fade" id="contractChangeOptionsModal" tabindex="-1" aria-labelledby="contractChangeOptionsModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="contractChangeOptionsModalLabel">{{$t('overview.change') + ' ' + $t('navigation.employees')}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    {{$t('overview.employee_choice')}}
                </div>
                <div class="modal-footer d-flex">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="setHref(0, savedData, true)">Nieuw contract genereren</button>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="setHref(2, savedData)">Tussentijdse wijziging</button>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import DataTable from '@/components/DataTable.vue';
import Column from 'primevue/column';
import FormPopup from '@/components/FormPopup.vue';
import moment from 'moment';
import router from '@/router';
import store from '@/store/user';

export default {
    name: 'Overview',
    props: {
        'moduleNamePlural': {},
        'moduleName': {},
        'sort': {},
        'sortOrder': { type: Number, default: 1},
        'itemService': {},
        'itemFormRef': {},
        'modalSize': {},
        'modalHeight': {},
        'modalWidth': {},
        'showDownload': { type: Boolean, default: false},
        'showCopy': { type: Boolean, default: false},
        'showDropdown': { type: Boolean, default: true},
        'showHeader': { type: Boolean, default: true},
        'pushLeft': { type: Boolean, default: false},
        'filterNMBRS': { type: Boolean, default: true},
        'filterGroups': [],
        'rows': { type: Number, default: 20},
    },
    components: {
        DataTable,
        Column,
        FormPopup
    },
    emits: 'refreshList',
    data() {
        return {
            items: {
                'data': [],
                'total': 0
            },
            loading: true,
            lazyParams: {},
            page: 1,
            filters: {},
            pageNumber: 1,
            extraFilters: {},
            prevFilterValue: '',
            
            item: {},
            itemLoading: false,
            isCopying: false,
            isMail: false,
            dateFrom: null,
            dateTo: null
        }
    },
    computed: {
        isHR() {
            return store.getters.getIsHR;
        }
    },
    methods: {
        onPage(event) {
            this.page = event.page + 1
            this.getIndex();
        },
        onSort(event) {
            this.page = 1;
            this.lazyParams = event;
            this.lazyParams.route = this.$route.name
            this.getIndex();
        },
        onFilter() {
            this.lazyParams.filters = this.filters;
            this.getIndex();
        },
        onChange(){
            if(!this.loading && this.prevFilterValue != this.filters['global'].value){
                this.prevFilterValue = (this.filters['global'].value + '').trim();
                this.onFilter();
            }
        },
        onDateChange(){
            if(!this.loading){
                this.lazyParams.from_date = this.dateFrom;
                this.lazyParams.to_date = this.dateTo;
                this.getIndex();
            }
        },
        onInput() {
            if(!this.loading && this.filters['global'].value == ''){
                this.onFilter();
                this.prevFilterValue = '';
            }
        },
        onEnter(){
            if(!this.loading){
                this.prevFilterValue = (this.filters['global'].value + '').trim();
                this.onFilter();
            }
        },
        initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },
        setExtrafilters(name, value) {
            if(value == 'all'){
                this.extraFilters[name] = null;
                const els = document.querySelectorAll('input[data-field="'+name+'"]');
                els.forEach(el => el.checked = false);
            }
            else{
                let values = this.extraFilters[name] ? this.extraFilters[name].split(',') : [];
                let index = values.indexOf('' + value);
                if(index != -1){
                    values.splice(index, 1);
                }
                else{
                    values.push(value);
                }
                this.extraFilters[name] = values.join(',');
                const els = document.querySelectorAll('#filter-'+name+'-all');
                els.forEach(el => el.checked = values.length == 0);
            }
            this.getIndexEmployees();
        },
        getIndex() {
            this.loading = true;
            if(this.$route.name === 'employees' || (this.$route.name === 'companies' && store.getters.getCurrentCustomerId) ){
                this.getIndexEmployees();
                return;
            }
            if(window.location.search.includes('audit-trail') && !this.prevFilterValue){
                let searchData = decodeURIComponent(window.location.search.split('audit-trail=')[1]);
                this.filters['global'].value = searchData;
                this.prevFilterValue = searchData;
                this.onFilter();
                return;
            }
            this.items = this.itemService.index();
            this.item = {}
            this.itemService.index(this.page, {
                lazyEvent: JSON.stringify(this.lazyParams)
            }).then(response => {
                this.items = response.data;
                this.loading = false;
            }).catch(error => {
                console.log('error', error)
            })
        },
        getIndexEmployees(fromState = null) {
            this.items = {};
            this.item = {};
            var storedEmployeeOverviewData = this.$parent.$parent.$parent.storedEmployeeOverviewData;
            if(storedEmployeeOverviewData && fromState && (fromState.meta.title == "Edit Employee" || fromState.meta.title == "Employee")){
                this.page = storedEmployeeOverviewData.page;
                this.lazyParams.filters.global.value = storedEmployeeOverviewData.filter;
                this.lazyParams.sortField = storedEmployeeOverviewData.sortField;
                this.lazyParams.sortOrder = storedEmployeeOverviewData.sortOrder;
                this.$refs.dt.$refs.paginator.d_first = (storedEmployeeOverviewData.page - 1) * 20;
                this.$refs.dt.$refs.paginator.$forceUpdate();
            }
            const pageNumber = this.page;
            this.itemService.indexByCompany({'customer_id': store.getters.getCurrentCustomerId, 'company_id': store.getters.getCurrentCompanyId}, 
                                             this.$route.name === 'companies' ? { lazyEvent: JSON.stringify(this.lazyParams)} : this.lazyParams.filters.global.value,
                                             this.lazyParams.sortField, 
                                             this.lazyParams.sortOrder,
                                             pageNumber, 
                                             this.extraFilters)
              .then(response => {
                this.items = {
                    current_page: response.data.current_page,
                    data: response.data.data,
                    first_page_url: null,
                    from: response.data.from,
                    last_page: response.data.last_page,
                    last_page_url: null,
                    links: null,
                    next_page_url: null,
                    path: null,
                    per_page: response.data.per_page,
                    prev_page_url: null,
                    to: response.data.to,
                    total: response.data.total
                };
                this.loading = false;
                this.$parent.$parent.$parent.storedEmployeeOverviewData = {
                    page: pageNumber,
                    filter: this.lazyParams.filters.global.value,
                    sortField: this.lazyParams.sortField,
                    sortOrder: this.lazyParams.sortOrder
                };
            }).catch(error => {
                console.log('error', error)
            })
        },
        resetFilters(){
            this.lazyParams.filters.global.value = '';
            this.extraFilters.employee_status_id = null;
            const els = document.querySelectorAll('input[data-field="employee_status_id"]');
            els.forEach(el => el.checked = false);
            document.querySelector('#filter-employee_status_id-all').checked = true;
        },
        addItem() {
            this.itemLoading = false;
            this.itemFormRef.reset();
            this.item = this.itemFormRef.getData();
            this.isCopying = false;
        },
        copyItem(item) {
            this.itemLoading = true;

            this.itemService.show(item.id).then(response => {
                this.itemLoading = false;
                this.itemFormRef.reset();
                this.item = this.itemFormRef.getData();
                this.item.isCopy = true;
                let data = response.data;
                data.file_info = [];
                if(data.start_date && data.end_date){
                    data.start_date = moment().quarter(moment().quarter() + 1).startOf('quarter').format('YYYY-MM-DD');
                    data.end_date = moment().quarter(moment().quarter() + 1).endOf('quarter').format('YYYY-MM-DD');
                }
                delete data.finalized;
                delete data.id;
                data.number = '';
                this.itemFormRef.setData(data);
                this.isCopying = true;
            }).catch(error => {
                this.toastError(error)
                console.log('error', error) // create notification-toaster for user error
            })
        },
        editItem(item, direct = false, type = null) {
            if(direct) {
                this.itemLoading = false;
                this.item = item;
                this.item.isReminder = type == 'reminder';
                this.item.endEmployemnt = type == 'endemployment';
                this.item.wontBeEmployed = type == 'wontbeemployed';
                this.itemFormRef.setData(item);
                this.isMail = true;
                return;
            }
            
            this.itemLoading = true;
            this.itemFormRef.reset();
            this.isCopying = false;
            
            this.itemService.show(item.id).then(response => {
                this.itemLoading = false;
                this.item = response.data
                this.itemFormRef.setData(response.data);
            }).catch(error => {
                this.toastError(error)
                console.log('error', error) // create notification-toaster for user error
            })
        },
        delItem(item, fromEmployees = false) {
            this.item = {...item};
            if(fromEmployees){
                this.item.employee_name = item.first_name + ' ' + item.last_name.replace('*', '');
            }
        },
        downloadPDF(item) {
            this.item = {...item};
            this.itemService.downloadPDF(this.item.id).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", this.item.number + ".pdf");
                document.body.appendChild(link);
                link.click();
            }).catch(error => {
                this.toastError(error)
            })

        },
        save() {
            let validate = this.itemFormRef.validate();
            if(!validate) return false;
            let item = this.itemFormRef.getData();
            
            if(this.item && this.item.id) {
                this.itemService.update(item).then(() => {
                    //this.itemFormRef.reset();
                    this.getIndex();
                    document.querySelector("#modal-close-button").click();
                }).catch(error => {
                    this.toastError(error)
                    console.log('error', error) // create notification-toaster for user error
                })
            } else {
                this.itemService.store(item).then(() => {
                    //this.itemFormRef.reset();
                    this.getIndex();
                    document.querySelector("#modal-close-button").click();
                }).catch(error => {
                        this.toastError(error)
                    console.log('error', error) // create notification-toaster for user error
                })
            }
            
        },
        del() {
            this.itemService.delete(this.item.id).then(() => {
                this.getIndex();
                document.querySelector("#deleteModal .btn-close").click();
            }).catch(error => {
                this.toastError(error)
                console.log('error', error) // create notification-toaster for user error
            })
            
        },
        setHref(mode, data, fromNMRS = false){
            let id = data.external_id ? data.external_id : (data.id ? data.id : data.employee_id);
            if(mode == 0){
                return router.push({ name: 'edit_employee_main', params: { id: fromNMRS ? id : data.contract.id } });
            }
            if(mode == 1){
                return router.push({ name: 'edit_employee_personalia', params: { id: id } });
            }
            id = data.external_id ? data.external_id : (data.contract ? data.contract.id : data.employee_id);
            if(mode == 2){
                return router.push({ name: 'edit_employee_profession', params: { id: id } });
            }
            if(mode == 3){
                return router.push({ name: 'edit_employee_contract', params: { id: data.contract ? data.contract.id : data.employee_id } });
            }
            if(mode == 4){
                return router.push({ name: 'edit_employee_salary', params: { id: id } });
            }
            return '#';
        },
        sync(data){
            console.log("sync", data.contract.id, store.getters.getCurrentCustomerId);
            this.itemService.sync(data.contract.id, store.getters.getCurrentCustomerId).then(response => {
                console.log('response', response);
                const el = document.getElementById('inv_button');
                if(el) el.click();
            }).catch(error => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    this.toastError(error)
                }
                else 
                {    
                    console.log('error', error.toJSON());
                }
                                
            })
        },
        sendSignRequest(data){
            let email = data.email;
            if(data.employee_status_id == 4){
                let company = this.$parent.$parent.$parent.companies.find(company => company.id == data.company_id);
                if(company){
                    email = company.representative_email;
                }
            }
            if(confirm("Weet je zeker dat je een Signrequest wilt versuren naar " + email + "?")){
                this.itemService.signrequest(data.contract.id).then(response => {
                    this.$toast.success('Signrequest verstuurd')
                }).catch(error => {
                    this.toastError(error)
                    console.log('error', error)
                })
            }
        }, 
        sendFinalizationRequest(data){
            const employeeName = data.first_name + ' ' + data.last_name.replace('*', '');
            if(confirm("Weet je zeker dat je " + employeeName + " in dienst wil laten worden?")){
                this.itemService.delete(data.id).then(response => {
                    this.$toast.success('Medewerker is nu in dienst')
                    this.getIndex()
                }).catch(error => {
                    this.toastError(error)
                    console.log('error', error)
                })
            }
        },
        setAuditTrail(data){
            location.href = location.origin + '/logs?audit-trail=' + encodeURIComponent(data.first_name + ' ' + data.last_name.replace('*', ''));
        },
        setDatePickerActive(e){
            e.target.type = 'date';
        },
        setDatePickerInctive(e){
            if(!e.target.value){
                e.target.type = 'text';
            }
        },
        onrowclick(e){
            if(document.getElementById('employee-overview') && e.data.contract){
                this.$router.push({ name: 'show_employee_main', params: { id: e.data.contract.id } });
            }
            else if(document.getElementById('log-overview') && e.data.user_agent){
                document.querySelector("#log-opener").click();
                this.editItem(e.data);
            }
        }
    },
    created() {
        this.initFilters();
    },
    mounted() {
        this.lazyParams = {
          first: 0,
          rows: this.$refs.dt.rows,
          sortField: this.sort,
          sortOrder: this.sortOrder,
          filters: this.filters,
          route: this.$route.name
        }
        if(this.$route.name === 'employees'){
            window.setTimeout(() => {
                if(!this.loading && !this.items.data){
                    this.getIndexEmployees();
                }
            }, 100);
        }
        else{
            this.getIndex();
        }
    }
}
</script>

<style scoped>

.dropdown-menu {
    inset: 0px 25px auto auto !important;
}

.dropdown-item {
    cursor: pointer;
}

</style>
